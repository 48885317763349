@import "../../styles/variables.scss";

.count-section {
  background: linear-gradient(
    180deg,
    rgba(226, 243, 250, 1) 19%,
    rgba(226, 243, 250, 1) 45%,
    rgba(240, 249, 253, 1) 70%,
    rgba(255, 255, 255, 1) 98%
  );
  height: 300px;
  padding: 40px;

  .sub-content {
    .countup {
      font-size: 32px;
      font-weight: 500;
    }

    span {
      font-size: 30px;
      font-weight: 400;
    }
    i {
      font-size: 30px;
    }

    .content {
      color: $accentColor;
    }
  }
}

@media (max-width: 480px) {
  .count-section {
    height: 400px;

    .count-sub-content {
      .col-md-3 {
        width: 50%;
      }

      .col-bd-none {
        border-left: none !important;
      }
    }
  }
}
