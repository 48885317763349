@import "../../styles/variables.scss";
.history-section {
  .timeline-section {
    margin-top: 30px;
    .timeline {
      max-width: 100%;
      list-style: none;
      padding: 20px 0 20px;
      position: relative;
    }
    .timeline:before {
      top: 60px;
      bottom: 205px;
      position: absolute;
      content: " ";
      width: 3px;
      background-color: #eeeeee;
      left: 50%;
      margin-left: -1.5px;
    }
    .arrowhead {
      width: 0;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -15px;
      display: none;

      border-top: 0 solid transparent;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 15px solid #eeeeee;
    }
    .timeline > li {
      min-height: 200px;
      width: 50%;
      padding-left: 42px;
      padding-right: 42px;
      margin-bottom: 30px;
      position: relative;
      float: left;
      clear: left;
    }

    /*Panel*/
    .timeline > li > .timeline-panel {
      width: 100%;
      float: left;
      border: 1px solid #d4d4d452;
      border-radius: 5px;
      /*padding: 20px;*/
      position: relative;
      // -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
      // box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
    }
    .timeline > li > .timeline-panel:before {
      position: absolute;
      top: 26px;
      right: -15px;
      display: inline-block;
      border-top: 15px solid transparent;
      // border-left: 15px solid #ccc;
      // border-right: 0 solid #ccc;
      border-bottom: 15px solid transparent;
      content: " ";
    }
    .timeline > li > .timeline-panel:after {
      position: absolute;
      top: 27px;
      right: -14px;
      display: inline-block;
      border-top: 14px solid transparent;
      border-left: 14px solid $historybgblue;
      border-right: 0 solid $historybgpink;
      border-bottom: 14px solid transparent;
      content: " ";
    }

    .timeline-panel img {
      width: 100%;
      padding: 10px 10px 0 10px;
    }

    /*Inverted panel*/
    .timeline > li.timeline-inverted > .timeline-panel {
      float: right;
    }
    .timeline > li.timeline-inverted > .timeline-panel:before {
      border-left-width: 0;
      border-right-width: 15px;
      left: -15px;
      right: auto;
    }
    .timeline > li.timeline-inverted > .timeline-panel:after {
      border-left-width: 0;
      border-right-width: 14px;
      left: -14px;
      right: auto;
    }

    .timeline > li.timeline-inverted {
      float: right;
      clear: right;
      margin-top: 0px;
      margin-bottom: 30px;
    }
    .timeline > li:nth-child(3) {
      margin-top: 100px;
    }

    /*Last panel*/
    // @media (min-width: 850px) {
    //   .timeline .last {
    //     margin-top: -40px;
    //   }
    //   .timeline .last .timeline-panel {
    //     margin-top: 75px;
    //     left: calc(50% + 60px);
    //   }
    //   .timeline .last .timeline-panel:before {
    //     top: -15px;
    //     right: calc(50% - 7px);
    //     border-top: 0 solid transparent;
    //     border-left: 15px solid transparent;
    //     border-right: 15px solid transparent;
    //     border-bottom: 15px solid #ccc;
    //   }
    //   .timeline .last .timeline-panel:after {
    //     top: -14px;
    //     right: calc(50% - 6.5px);
    //     border-top: 0 solid transparent;
    //     border-left: 14px solid transparent;
    //     border-right: 14px solid transparent;
    //     border-bottom: 14px solid #fff;
    //   }
    // }

    /*Badge*/
    .timeline > li > .timeline-badge {
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 1.2em;
      text-align: center;
      position: absolute;
      top: 16px;
      right: 5px;
      z-index: 100;
      border-top-right-radius: 50%;
      border-top-left-radius: 50%;
      border-bottom-right-radius: 50%;
      border-bottom-left-radius: 50%;
      cursor: default;
    }
    .timeline > li.timeline-inverted > .timeline-badge {
      left: 5px;
    }

    .timeline > li > .timeline-badge:before {
      position: absolute;
      top: 58%;
      right: -11px;
      content: " ";
      display: block;
      width: 12px;
      height: 12px;
      margin-top: -10px;
      // background: #fff;
      border-radius: 10px;
      z-index: 10;
    }
    .timeline > li.timeline-inverted > .timeline-badge:before {
      left: -11px;
    }

    /*Content*/
    .timeline-title {
      margin-top: 0;
      padding: 12px;
      color: inherit;

      .title-content {
        margin: 0;
        margin-bottom: 5px;
        font-size: 18px;
      }
      .content {
        margin-bottom: 0;
      }
    }
    .timeline-body > p,
    .timeline-body > ul {
      padding: 0 20px 20px 20px;
      margin-bottom: 0;
    }
    .timeline-body > p + p {
      margin-top: 5px;
    }

    .timeline-footer {
      background-color: #f4f4f4;
      padding: 10px;
      text-align: center;
    }
    .timeline-footer > a {
      padding: 20px;
      cursor: pointer;
      text-decoration: none;
    }

    /*Placement small devices*/
    // @media (max-width: 850px) {
    //   ul.timeline:before {
    //     bottom: -15px;
    //   }
    //   ul.timeline:before,
    //   .arrowhead {
    //     left: 10px;
    //   }

    //   ul.timeline > li {
    //     min-height: 50px;
    //     margin-bottom: 20px;
    //     padding-right: 5px;
    //     position: relative;
    //     width: 100%;
    //     float: left;
    //     clear: left;
    //   }
    //   ul.timeline > li > .timeline-panel {
    //     width: calc(100% - 40px);
    //     width: -moz-calc(100% - 40px);
    //     width: -webkit-calc(100% - 40px);
    //   }

    //   ul.timeline > li > .timeline-panel {
    //     float: right;
    //   }

    //   ul.timeline > li > .timeline-panel:before {
    //     border-left-width: 0;
    //     border-right-width: 15px;
    //     left: -15px;
    //     right: auto;
    //   }

    //   ul.timeline > li > .timeline-panel:after {
    //     border-left-width: 0;
    //     border-right-width: 14px;
    //     left: -14px;
    //     right: auto;
    //   }

    //   .timeline > li.timeline-inverted {
    //     float: left;
    //     clear: left;
    //     margin-top: 0;
    //     margin-bottom: 20px;
    //   }

    //   ul.timeline > li > .timeline-badge,
    //   ul.timeline > li.timeline-inverted > .timeline-badge {
    //     left: 15.5px;
    //   }
    //   ul.timeline > li > .timeline-badge:before,
    //   ul.timeline > li.timeline-inverted > .timeline-badge:before {
    //     left: -11.5px;
    //   }
    // }

    .timeline-vert {
      .timeline-badge:before {
        background-color: $primaryColor;
      }

      .timeline > li > .timeline-panel:after {
        background-color: $primaryColor;
      }
      .bg-blue {
        background-color: $historybgblue;
        .title-content {
          color: $primaryColor;
        }
      }
    }

    .timeline-inverted {
      .timeline-badge:before {
        background-color: $secondaryColor;
      }
      .bg-pink {
        background-color: $historybgpink;

        .title-content {
          color: $secondaryColor;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .history-section {
    .timeline-section {
      // .timeline {
      //   max-width: 90%;
      // }
      .timeline > li.timeline-vert {
        .timeline-badge {
          left: 8%;
        }
      }

      .timeline > li {
        padding-left: 70px;
      }
      .timeline > li.timeline-vert > .timeline-badge::before {
        left: -15px !important;
      }

      .timeline > li.timeline-inverted > .timeline-badge::before {
        left: 15px !important;
      }
    }
    .timeline::before {
      left: 25px !important;
      height: 83%;
    }

    .timeline > li {
      width: 100% !important;
      margin-bottom: 0 !important;
    }

    .timeline > li.timeline-vert > .timeline-panel:after {
      border-right: 0 solid $historybgpink;
      transform: rotate(180deg);
      left: -14px !important;
      position: absolute;
      right: unset;
    }

    .timeline > li:nth-child(3) {
      margin-top: 0 !important;
    }
  }
}
