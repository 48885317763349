@import "../../styles/variables.scss";

.stepper-block {
  margin-bottom: 20px;
  .text-block {
    background: $white;
    box-shadow: $blockShadow;

    position: sticky;
    top: 0;
    z-index: 1111;

    .stepper-head {
      padding: 12px 16px 8px 16px;
      border-left: 1px solid #ccc;
      margin: 0;
      &:hover {
        background: $primaryColor;
        .main-content {
          color: $white;
        }
      }
      .main-content {
        // color: $stepperTitle;
      }
      input {
        -webkit-appearance: none;
        visibility: hidden;
        height: 0;
        width: 0;
      }

      .title-wrapper {
        .item-icon {
          background-color: $primaryColor;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          i {
            color: $white;
            font-size: 13px;
          }
        }
      }
    }
    .active-head {
      background: $primaryColor;
      .main-content {
        color: $white;
      }
    }
  }
  .stepper-bottom {
    border-bottom: 0;
    .stepper-bottom-block {
      .next-btn {
        button {
          margin-right: 10px;
        }
        .main-btn {
          width: fit-content;
        } 
      }
    }
  }
}
