@import "../../styles/variables.scss";
.footer-section {
  background: linear-gradient(
    180deg
    , #D4EDF8 0%, rgba(212, 237, 248, 0) 100%);
  .footer-title {
    font-size: 24px;
    font-weight: 600;
    img {
      // margin-right: 15px;
      height: 45px;
    }
    .sub-title {
      color: #29ABE2;
    }
  }
  padding: 50px 0;
  background: linear-gradient(180deg, #D4EDF8 0%, rgba(212, 237, 248, 0) 100%);
  .nav-list {
    display: flex;
    justify-content: space-between;
    p {
      margin-top: 10px;
    }
  }

  .app-content {
    text-align: left;
    .img-content {
      display: flex;
    }
    .img-mg {
      margin-bottom: 20px;
    }
  }

  .footer-app {
    // display: flex;
    // justify-content: flex-end;
    // text-align: initial;
  }

  a {
    text-decoration: none;
    color: $black;
  }
}

.mt-10 {
  margin-top: 10px !important;
}
@media (max-width: 768px) {
  .footer-section {
    .footer-app {
      justify-content: initial;
    }

    .text-center {
      text-align: initial !important;
    }
  }
}

@media (max-width: 480px) {
  .footer-section {
    .text-center {
      .col-sm-3 {
        width: 25%;
      }
    }
  }
}

@media (max-width: 375px) {
  .footer-section {
    .text-center {
      .col-sm-3 {
        width: 33%;
      }
    }

    .mobileview-col {
      flex-direction: column;
    }
  }
}
