@import "../../styles/variables.scss";

.benefits-section {
  .main-title {
    font-size: 40px;
  }
  .benefit-content {
    margin-top: 30px;

    .ben-icon {
      //   padding: 12px;
      padding: 18px;
      height: 75px;
      width: 75px;
      border-radius: 15px;
      margin: auto;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }

      i {
        font-size: 35px;
      }
    }

    .b-1 {
      background: $lightprimaryColor;
    }

    .b-2 {
      background-color: $lightSecondaryColor;
    }

    .b-3 {
      background-color: $lightGreen;
    }

    .b-4 {
      background-color: $lightPurple;
    }
  }

  .benefit-col {
    margin-bottom: 30px;
  }
}

.benefit-sub-content {
  .content {
    display: -webkit-inline-box;

    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: "480px") {
  .benefits-section {
    .main-title {
      font-size: 27px;
    }
}
}