@import "../../styles/variables.scss";
.missionVision-section {
  .missionV-content {
    margin-top: 30px;
    .ben-icon {
      //   padding: 12px;
      padding: 18px;
      height: 75px;
      width: 75px;
      border-radius: 15px;
      margin: auto;

      i {
        font-size: 35px;
      }

      img {
        height: 40px;
      }
    }

    .b-1 {
      background: $lightprimaryColor;
    }

    .b-2 {
      background-color: $lightSecondaryColor;
    }

    .b-3 {
      background-color: $lightGreen;
    }

    .b-4 {
      background-color: $lightPurple;
    }
  }

  .missionV-col {
    margin-bottom: 60px;
    box-shadow: 1px 1px 4px rgba(68, 145, 188, 0.25);
    border-radius: 10px;
    padding: 35px 25px;
    min-height: 355px;
  }
}
@media (max-width: 1380px) {
  .missionVision-section {
    .missionV-col {
      height: 383px;
    }
  }
}

@media (max-width: 992px) {
  .missionVision-section {
    .missionV-col {
      height: 340px;
    }
  }
}
