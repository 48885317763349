.previewpage-headings {
  color: #fff;
  background-color: #29abe2;
  padding: 8px;
  margin-bottom: 20px;
  margin-left: 50px;
  margin-right: 50px;
}
.containerr {
  display: grid;
  height: auto;
  width: auto;
  place-items: center;
}
.preview-download {
  display: flex;
  margin-left: 50px;
  margin-bottom: 20px;
}
.preview-items {
  display: flex;
  justify-content: space-between;
}
.preview-items img {
  margin-top: 50px;
  margin-right: 100px;
  width: 100px;
}

.preview-download button {
  padding: 5px;
  width: 13vw;
  background-color: #29abe2;
  color: white;
  border-radius: 10px;
  border: none;
}
.preview-download button:hover {
  background-color: #e26029;
  color: white;
}
.previewpage-contents {
  margin-top: 10px;
  margin-left: 60px;
  height: auto;
  width: 45vw;
  border: 2px solid #d3d3d3;
  border-radius: 10px;
  margin-bottom: 30px;
}
.previewpage-items {
  /* display: grid;
  place-items: start; */
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
}
.previewpage-items h1 {
  color: #29abe2;
  font-size: 20px;
  font-weight: 800;
  margin-top: 16px;
}
.previewpage-items span {
  font-size: 14px;
  font-weight: 800;
}
.previewpage-items h2 {
  color: #29abe2;
  font-size: 15px;
  margin-top: 10px;
  font-weight: 800;
}
.schooldetails {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 5px;
}
.schooldetails img {
  width: 80px;
}
.namedetails {
  display: grid;
}
.namedetails h1 {
  margin-top: 10px;
  color: #29abe2;
  font-size: 20px;
  font-weight: 600;
}
.namedetails h2 {
  font-size: 13px;
  font-weight: 300;
}
