.aboutdetails-section {
  img {
    height: 300px;
    width: 100%;
    object-fit: cover;
  }
  .details-content {
    margin: 20px 0;
    text-align: initial;
    margin-right: 100px;

    .content {
      font-size: 18px;
    }
  }
}

@media (max-width: 992px) {
  .aboutdetails-section {
    .details-content {
      margin-right: 0;
    }
  }
}

@media (max-width: 768px) {
  .aboutdetails-section {
    img {
      height: 400px;
    }
  }
}
