
.infocard-items {
  background-color: #fff;
  max-height: 65vh;
  height: fit-content;
  width: 95%;
  box-shadow: 1px 1px 4px rgba(68, 145, 188, 0.25);
  border-radius: 10px;
  padding: 35px 10px;
}
.infocard-content{
  display: flex;
  justify-content: flex-start;
  gap: 25px;
}
.infocard-grid h1 {
  font-size: 20px;
  font-weight: 500;
  text-align: start;
  color: #303030;
}
.infocard-grid span {
  background-color: #e26029;
  width: 100px;
  border-radius: 15px;
  height: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.infocard-grid p {
  font-size: 16px;
  line-height: 28px;
  width: 93%;
  text-align: justify;
  }
  
.infocard-grid span{
  background-color: none;
}
.readmore{
  text-decoration: none;
}

@media (max-width: 768px) {
.infocard-items {
  background-color: #fff;
  max-height: 65vh;
  height: fit-content;
  width: 97%;
  box-shadow: 1px 1px 4px rgba(68, 145, 188, 0.25);
  border-radius: 10px;
  padding: 35px 10px;
}
  .infocard-content{
    display: grid;
    place-items: center ;
    gap: 20px;
  }
  .infocard-grid h1 {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: #303030;
  }
  }

  @media (max-width: 480px) {
    .infocard-items {
      background-color: #fff;
      max-height: 70vh;
      height: fit-content;
      width:100%;
      box-shadow: 1px 1px 4px rgba(68, 145, 188, 0.25);
      border-radius: 10px;
      padding: 35px 10px;
    }
      .infocard-content{
        display: grid;
        place-items: center ;
        gap: 20px;
      }
      .infocard-grid h1 {
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        color: #303030;
      }
    }
  
  