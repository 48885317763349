.schoolcard{
    background-color: #ffffff;
    box-shadow: 1px 1px 4px rgba(68, 145, 188, 0.25);
    padding: 10px;
    width: 100%;
    border-radius: 10px;
}
.schoolcard:hover{
    cursor: pointer;
}

.schoolcard-items{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
}
.schoolcard-items img{
    height: 13vh;
    width: 7vw;
}
/* .schoolcard-contents{
    display: grid;
    place-items: start;
} */
.schoolcard-contents h1{
    text-align: start;
    font-size: 17px;
}
.schoolcard-contents h2{
    font-size: 14px;
    text-align: start;
    color: #999999;
}

@media(max-width: 992px) {
    .schoolcard-items img{
        height: 13vh;
        width: 9vw;
    }
    .schoolcard-contents h1{
        text-align: start;
        font-size: 14px;
    }
    .schoolcard-contents h2{
        font-size: 12px;
        text-align: start;
        color: #999999;
    }
  }


@media(max-width: 768px) {
    .schoolcard-items img{
        height: 13vh;
        width: 13vw;
    }
    .schoolcard-contents h1{
        text-align: start;
        font-size: 18px;
    }
    .schoolcard-contents h2{
        font-size: 16px;
        text-align: start;
        color: #999999;
    }
    .schoolcard{
        margin-left: 5px;
        width: 90%;
    }
  }


@media(max-width: 480px) {
    .schoolcard-items img{
        height: 10vh;
        width: 13vw;
    }
    .schoolcard-contents h1{
        text-align: start;
        font-size: 16px;
    }
    .schoolcard-contents h2{
        font-size: 13px;
        text-align: start;
        color: #999999;
    }
    .schoolcard{
        margin-left: 5px;
        width: 90%;
    }
  }


