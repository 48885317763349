.thankyou-page {
  .thankyou-page-banner {
    position: relative;
    top: 0;
    width: 100%;
    background-color: #101c3d;
    height: 65px;
    object-fit: cover;
    margin-bottom: 100px;
  }
  .thankyou-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .main-btn {
      justify-content: center;
      background: #29abe2;
      margin-top: 16px;
      height: 42px;
    }
    img {
      height: 250px;
      margin-bottom: 24px;
    }
  }
}
