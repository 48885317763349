@import "../../styles/variables.scss";

.required-input {
  color: red;
}
.contact-section {
  .contact-img {
    width: 100%;
  }

  .content-style {
    margin: 0 0 20px;
  }
  .main-title {
    padding-bottom: 0;
  }

  form {
    .input-field {
      margin-bottom: 20px;
      padding: 10px 0;

      p {
        margin-bottom: 3px;
      }

      .error {
        color: red;
      }

      input,
      textarea {
        background-color: #f5f5f5;
        border: 1px solid #f5f5f5;
        border-radius: 10px;
        padding: 15px;
        -webkit-appearance: none;
        margin: 0;
        -moz-appearance: textfield;
      }

      textarea {
        height: 154px;
        resize: none;
      }
    }

    .main-btn {
      margin-top: 20px;
    }
  }

  .contact-info {
    margin-top: 100px;

    .d-flex {
      margin-bottom: 10px;

      i {
        color: $primaryColor;
        font-size: 20px;
        margin: 0 10px 0 20px;
      }

      p {
        font-size: 16px;
        text-align: initial;
        width: calc(100% - 60px);
        margin-bottom: 0;
        a {
          text-decoration: none;
          color: unset;
        }
      }
    }
  }

  .social-media {
    margin-left: 20px;
    img {
      margin-right: 15px;
    }
  }
}
