@import "../../styles/variables.scss";
.contact-info {
  text-align: initial;
}
.schooladmission {
  .ourclient-content {
    min-height: 140px;
    margin: 30px 0 30px 0;
    padding: 15px;
    img {
      width: 100px;
      margin-right: 20px;
      height: 100px;
    }
    :hover {
      cursor: pointer;
    }

    .client-details {
      width: calc(100% - 120px);
      .content {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 8px;
      }

      .label-text {
        margin-bottom: 0;
      }
    }
  }
}
.stickySearch {
  // position: -webkit-sticky;
  // position: fixed;
  // top: 300px;

  position: -webkit-sticky;
  position: sticky;
  top: 0px;
}
.filter-button {
  border: none;
  background-color: white;
  color: #29abe2;
  text-decoration: underline;
}
