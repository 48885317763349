.ourclient-section {
  .schoolSearch {
    display: flex;
    justify-content: center;
    .search-button {
    margin-top: 10px;
    padding: 0px 39px;
    height: 8.2vh;
    background-color: #29abe2;
    color: #fff;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    margin-top: 2vh;
    }
    .searchicon:hover{
      cursor: pointer;
    }
  }
  .ourclient-content {
    min-height: 140px;
    margin: 30px 0 30px 0;
    padding: 15px;
    img {
      width: 100px;
      margin-right: 20px;
      height: 100px;
    }
    :hover {
      cursor: pointer;
    }

    .client-details {
      width: calc(100% - 100px);
      .content {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 8px;
      }

      .label-text {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: "480px") {
  .ourclient-section {
    .ourclient-content {
      min-height: 110px;

      img {
        width: 80px;
        margin-right: 20px;
        height: 82px;
      }
    }
  }
}
