.feature-section {
  .main-title {
    font-size: 40px;
  }
  .feature-content {
    margin-top: 60px;

    img {
      max-width: 100%;
      height: 170px;
    }

    .sub-content {
      min-height: 180px;

      .content {
        display: -webkit-inline-box;
        margin: 10px 30px;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        min-height: 90px;
        // min-height: 100px;
      }

      .d-flex {
        justify-content: center;

        img {
          height: 15px;
          width: 15px;
          margin: 5px 10px;
        }
      }
    }
  }
}

@media (max-width: "480px") {
  .feature-section {
    .main-title {
      font-size: 27px;
    }
}
}
