@import "../../styles/variables.scss";

.dropzone-section {
  position: relative;
  .dropzone {
    width: 200px;
    height: 200px;
    border: 2px dotted $borderColor;
     
  }

  .upload-img {
    height: 100%;
    width: 100%;
  }
  .selected-image {
    position: relative;
    width: 100%;
    height: 100%;

    
  }
  .trash-icon {
    i {
      color: $white;
      position: absolute;
      top: 0;
      right: 0;
      background-color: $tagred;
      padding: 4px;
      border-radius: 5px;
      z-index: 99;
 
    }
  }
}
 
