
.term-of-use-section {
    text-align: left;
    ul {
        list-style: none;
        padding: 0;
        li {
            padding-left: 20px;
        }
    }
    h5 {
        margin-bottom: 10px;
        margin-top: 30px;
    }
    p {
        margin-bottom: 10px;
    }
}