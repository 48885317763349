@import "../../styles/variables.scss";

.mobileapp-section {
  background-color: $accentColor;
  padding: 30px;
  position: relative;
  overflow: hidden;

  .mobileapp-content {
    .main-title {
      color: $white;
    }

    .content-style {
      margin: 0 0 20px;
    }

    .sub-content {
      color: $white;
      text-align: initial;

      .d-flex {
        li {
          font-size: 16px;
        }
      }
    }

    .sub-content-text {
      font-size: 18px;
      color: white;
      text-align: initial;
    }

    .app-content {
      .img-content {
        display: flex;
      }
      .img-mg {
        margin-right: 10px;
      }
    }
  }

  .app-img {
    position: absolute;
    height: 350px;
    bottom: 0;
    right: 60px;
  }

  .appimg {
    height: 350px;
    position: absolute;
    top: -78px;
    right: 300px;
  }
}

@media (max-width: 992px) {
  .mobileapp-section {
    .mobileapp-content {
      .sub-content-text {
        font-size: 15px;
      }
    }
  }
}

@media (max-width: 768px) {
  .mobileapp-section {
    .appimg {
      right: 260px;
    }
  }
}

@media (max-width: 480px) {
  .mobileapp-section {
    .app-img,
    .appimg {
      display: none;
    }
  }
}
