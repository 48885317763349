@import "../../styles/variables.scss";

.declaration-info {
  text-align: initial;

  .declaration-detail {
    ol {
      li {
        font-size: 14px;
      }
    }
  }

  .declaration-img {
    .image-upload {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        margin-bottom: 8px;
        font-size: 14px;
        text-align: center;
      }
    }

    .file-upload {
      display: flex;
      margin-bottom: 10px;

      input {
        margin-left: 10px;
      }
    }
  }
  .alert-secondary {
    padding: 15px;
    .declaration-footer {
      display: flex;
      align-items: center;
      margin-bottom: 3px;
      i {
        margin-right: 8px;
      }
    }
    p {
      margin-bottom: 0;
      font-weight: 400;
      font-size: 14px;
    }
  }
}
.pdf {
  position: relative;
  

  .trash-icon {
     
      width: 25px;
    height: 25px;
    position: absolute;
    top: -20px;
    right: -30px;
    border-radius: 50%;
    // border: 1px solid black;
    text-align: center;
    line-height: 24px;
   
  }
}
