.clientpage-section {
  .clientpage-content {
    min-height: 140px;
    margin: 30px 0 30px 0;
    padding: 15px;
    img {
      width: 100px;
      margin-right: 20px;
      height: 100px;
    }

    .client-details {
      width: calc(100% - 100px);
      .content {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 20px;
      }

      .label-text {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: "480px") {
  .clientpage-section {
    .clientpage-content {
      min-height: 110px;

      img {
        width: 80px;
        margin-right: 20px;
        height: 82px;
      }
    }
  }
}
