@import "../../styles/variables.scss";

.navbar-section {
  position: relative;
  .nav-wrapper {
    margin-top: 10px;
    position: absolute;
    width: 100%;
    .navbar {
      .nav-bar-left-side {
        display: flex;
        align-items: center;
        width: 100%;
      }
      .nav-flex {
        justify-content: space-between;
        display: flex;
      }
    }
    .nav-header {
      .navbar-nav {
        justify-content: center;
        align-items: center;
        .nav-item {
          .active {
            color: #fff !important;
            border-bottom: 1px solid #fff;
          }
          .nav-link:hover {
            color: #fff !important;
          }
        }
      }
    }
    .navbar-expand-lg {
      display: unset;
    }
  }

  .nav-title {
    font-weight: bold;
    color: red;
  }

  .navbar-light {
    z-index: 11;
    .navbar-brand {
      color: $white;
      margin-left: -10px;
      font-size: 24px;
    }
  }

  .navbar-nav {
    .nav-link {
      color: rgb(214, 212, 212) !important;
      padding: 8px;
      margin: 5px 20px;
      align-self: center;
    }

    .main-btn {
      background: $white;
      color: $secondaryColor;
      // border: 1px solid $black;
      text-decoration: none;
    }
  }

  .navbar-collapse {
    justify-content: flex-end;

    // .navbar-nav {
    //   .nav-item {
    //   }
    // }
  }
  img {
    margin: 10px;
    height: 45px;
  }

  .navbar-light .navbar-brand:focus,
  .navbar-light .navbar-brand:hover {
    color: #fff;
  }
}

@media (max-width: 992px) {
  .navbar-section {
    .navbar-toggler {
      right: -0px;
    }
    .navbar-nav {
      .nav-link {
        margin: 5px 10px;
      }
    }
  }
}

@media (max-width: 768px) {
  .navbar-section {
    .nav-bar-left-side {
      display: unset !important;
    }
    .navbar-toggler {
      right: 40px;
      background-color: #fffffffa;
    }
    ul {
      width: auto !important;
      padding: 20px;
      right: 0;
      top: 60px;
    }
    .navbar-nav {
      background-color: $accentColor;
      text-align: end;
      width: 100%;
      .nav-link {
        color: $white !important;
      }
    }
  }
}

@media (max-width: 480px) {
  .navbar-section {
    .navbar-light {
      .navbar-toggler {
        right: 20px;
      }
    }
  }
}
@media (max-width: 376px) {
  .navbar-section {
    .navbar-light {
      .navbar-toggler {
        right: 15px;
      }
    }
  }
}
.pop {
  position: fixed;
  bottom: 50px;
  right: 25px;
  z-index: 9;

  .addmission_button {
    margin-top: 10px;
    padding: 12px 25px;
    background-color: #29abe2;
    color: #fff;
    border: none;
    border-radius: 50px;
    font-size: 18px;
  }
  .addmission_button:hover {
    background-color: #e26029;
    cursor: pointer;
  }
}
