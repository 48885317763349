@import "../../styles/variables.scss";

.client-section {
  padding: 20px;
  margin-top: 60px;
  .owl-theme {
    .owl-nav:hover{
      .owl-next:hover{
        background: unset;
    color: $primaryColor;
    text-decoration: none;
      }
      .owl-prev:hover{
        background: unset;
    color: $primaryColor;
    text-decoration: none;
      }
    }
  }
  
  .alignCenter {
    justify-content: space-between;
    .owl-stage-outer{
      position: relative;
      .owl-stage{
        height: 180px;
      }
    }
   
    .owl-nav{
      position: absolute;
      width: 100%;
      top: 18px;
    }
    .owl-prev{
      font-size: 40px !important;
      float: left;
    }
    .owl-next{
      font-size: 40px !important;
      float: right;
    }
    .owl-dots{
      display: none;
    }
    .owl-carousel{
      justify-content: center;
display: flex;
    }
    .owl-stage-outer{
      width: 96%;
    }
  }

  .content-style {
    margin: 0 0 20px;
  }
}

.img-width {
  width: 100%;
  object-fit: contain;
  object-position: center;
  height: 125px;
}

@media (max-width: 480px) {
  .client-section {
    margin-top: 80px;
    .row {
      .col-md-3 {
        width: 50%;
      }
    }
  }
}


@media (max-width: 768px) {
  .client-section {
    margin-top: 80px;
    .owl-carousel .owl-stage-outer{
     height: 100%; 
    }
    .owl-stage-outer{
      position: relative;
      .owl-stage{
        height: 200px;
      }
    }
  }
}