@import "../../styles/variables.scss";

.banner-section {
  .banner-bg {
    position: relative;
    top: 0;
    width: 100%;
    background-color: $accentColor;
    clip-path: polygon(0 0, 100% 0, 100% 60%, 0 100%);
    height: 700px;
    object-fit: cover;

    .bg-img {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }

  .banner-content {
    // padding: 30px;
    width: 50%;
    position: absolute;
    top: 200px;
    color: $white;
    text-align: initial;

    .main-title {
      font-size: 56px;
      text-align: initial;
    }

    .content-style {
      margin: 0 0 20px;
    }

    p {
      margin: 32px 0;
      font-size: 18px;
    }
  }

  .app-img {
    position: absolute;
    top: 180px;
    right: 180px;
    height: 500px;
  }
}

@media (max-width: 1366px) {
  .banner-section {
    .app-img {
      right: 55px;
    }
  }
}

@media (max-width: 992px) {
  .banner-section {
    .banner-bg {
      height: 780px;
    }

    .app-img {
      top: 198px;
      right: 10px;
      height: 450px;
    }

    .banner-content {
      .main-title {
        font-size: 45px;
      }
    }
  }
}

@media (max-width: 768px) {
  .banner-section {
    .app-img{
      position: unset;
    }
    .banner-bg {
      height: 500px;
    }
    .banner-content {
      top: 70px;
      width: 90%;

      .main-title {
        font-size: 30px;
      }
    }
  }
}

@media (max-width: 480px) {
  .banner-section {
    .banner-content {
      width: 90%;
    }

    .app-img {
      top: 500px;
      right: 5px;
      height: 352px;
    }
  }
}

@media (max-width: 376px) {
  .banner-section {
    .app-img {
      right: 8px;
      height: 300px;
    }
  }
}

@media (max-width: 360px) {
  .banner-section {
    .app-img {
      right: 60px;
      height: 249px;
      top: 545px;
    }
  }
}