.demopage-section {
  .mg-70{
    width: 75%;
margin: 0 auto;
  }
  .card-section {
    padding: 30px 60px;
  }

  form {
    text-align: initial;

    .input-field {
      margin-bottom: 20px;
      padding: 10px 0;

      p {
        margin-bottom: 3px;
      }

      .error {
        color: red;
      }

      input,
      textarea {
        background-color: #f5f5f5;
        border: 1px solid #f5f5f5;
        border-radius: 10px;
        padding: 15px;
        width: 100%;
        height: 45px;
        -webkit-appearance: none;
        margin: 0;
        -moz-appearance: textfield;

      }

      textarea {
        height: 154px;
        resize: none;
        width: 100%;
      }
    }

    .main-btn {
      margin-top: 20px;
      padding: 10px 20px;
    }

    .mr-2 {
      margin-right: 20px;
    }

    .mr-1 {
      margin: 10px;
    }
  }
}

.spin-size {
  height: 16px;
  width: 16px;
}



@media (max-width: 375px) {
  .demopage-section {
    .card-section {
      padding: 20px;
    }
  }
}