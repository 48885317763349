.schoolcardslider-container{
    padding: 10px;
    display: none;

}
.schoolcardslider-contents {
    display: grid;
    height: auto;
    width: auto;
    place-items: center;
    background-color: #fff;
    box-shadow: 1px 1px 4px rgba(68, 145, 188, 0.25);
    margin-left: 3%;
}

.owl-carousel .owl-item img {
    margin-top: 5px;
    object-fit: contain;
    margin: 0 auto;
    width: 70%;
    height: 70%;
    object-fit: cover;
    margin-bottom: 10px;
    /* height: 80px;
    width: 5px; */
}
.img-wrapper{
    margin-bottom: 10px;
}
.owl-title{
    font-size: 10px;
    font-weight: 500;
    margin-bottom: 0
}
.owl-card{
    background-color: #fff;
    box-shadow: 1px 1px 4px rgb(68 145 188 / 25%);
    border-radius: 3px;
    margin-top: 4px;
    padding: 16px;
    height: 25vh;
}
.owl-content{
    font-size: 10px;
    font-weight: 400;
    margin-bottom: 0

}
.schoolcardslider-contents h1 {
   font-size: 10px;
   font-weight: 600;
   margin-top: 5px;
}
.schoolcardslider-contents h2 {
    margin-top: -15px;
    font-size: 10px;
    font-weight: 300;
 }


 @media (max-width: 768px){
.schoolcardslider-container{
    display: flex;
    max-width: 700px;
}

.schoolcardslider-contents{
    width: fit-content;
    /* height: fit-content; */
    height: 30vh;
    padding: 10px;
}
.owl-carousel .owl-item img{
    display: block;
    width: 70%;
    margin-bottom: 10px;
}

 }


 @media (max-width: 480px){

    .schoolcardslider-contents{
        width: 25vw;
        /* height: 25vh; */
        padding: 10px;
    }
    .owl-carousel .owl-item img{
        display: block;
    }
 }