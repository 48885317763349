@import "../../styles/variables.scss";

.subFeature-section {
  .subFeature-content {
    text-align: initial;

    .main-title {
      font-size: 25px;
    }
    p {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 3px;
    }

    img {
      width: 100%;
    }
  }

  .featureMark {
    .main-title {
      text-align: initial;
    }
    .content-style {
      margin: 0 0 20px;
    }
    .featureMark-content {
      .sub-feature-icon {
        border-radius: 5px;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        img {
          width: 35px;
          height: 35px;
        }
      }
      

      .feature-details {
        width: calc(100% - 70px);

        .main-title {
          font-size: 24px;
          padding-bottom: 0;
        }

        .content {
          font-size: 16px;
          line-height: 2;
        }
      }
    }
  }
}
