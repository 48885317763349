@import "../../styles/variables.scss";


.contact-info {
  .address-detail {
    text-align: initial;
    .blue-text {
      margin-bottom: 10px;
    }
    .checkbox-block {
      .radio-label {
        label {
          margin: 0 0 0 10px;
        }
      }
    }
  }
}
