.school-container {
  width: 70%;
}
.school_info {
  display: grid;
  margin-top: 5vh;
  width: 97%;
}

.school_info label {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  font-size: 40px;
}

.schoolimage {
  height: 35vh;
  width: 10vw;
}
.principleimage {
  border-radius: 10px;
  height: 30vh;
  width: 10vw;
  box-shadow: 1px 1px 4px rgba(68, 145, 188, 0.25);
}
.school_info p {
  margin-top: 10px;
  margin-left: 0px;
  text-align: start;
  font-size: 16px;
  line-height: 28px;
  width: 100vw;
}
.school_info h4 {
  background-color: #e26029;
  width: 100px;
  border-radius: 15px;
  height: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.brochure {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  padding: 0 20px 0 0;
  margin-top: 10px;
}
.gallary {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px;
  padding: 0 20px 0 0;
  margin-top: 10px;
}
.gallary img {
  width: 13.1vw;
  height: 25vh;
  object-fit: cover;
}
.brochure img {
  width: 13.1vw;
  height: 25vh;
  object-fit: cover;
}
.showbutton {
  display: flex;
  justify-content: flex-start;
  margin-top: 2vh;
  gap: 1vh;
}
.showbutton2 {
  display: flex;
  justify-content: center;
  gap: 1vh;
  margin-top: 2vh;
}
.showbutton:hover,
.showbutton2:hover {
  cursor: pointer;
}
.showmore {
  font-weight: 600;
  color: #29abe2;
  /* color: #e26029; */
  font-size: 2vh;
  padding: 1vh;
}
.showless {
  font-weight: 600;
  color: #e26029;
  font-size: 1.8vh;
  padding: 1vh;
}
.infobody-card {
  margin-top: 10px;
  display: grid;
  gap: 10px;
}
.missionicon {
  display: grid;
  place-items: start;
  width: 60px;
  background: #eaf7fc;
  padding: 18px;
  height: 75px;
  width: 75px;
  border-radius: 15px;
  margin-left: 10px;
}
.visionicon {
  width: 60px;
  background: #fcefea;
  padding: 18px;
  height: 75px;
  width: 75px;
  border-radius: 15px;
  margin-left: 10px;
}
.objicon {
  width: 60px;
  background: #ecf4ef;
  padding: 18px;
  height: 75px;
  width: 75px;
  border-radius: 15px;
  margin-left: 10px;
}

.slider-title label{
  display: none;

}


/* ========== info contents ==========  */
.info_contents {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* gap: 3rem; */
}
.info_contents img {
  object-fit: cover;
  width: 85%;
  margin-top: 20px;
  margin-left: 1.3rem;
  border-radius: 10px;
}
.info_contents p {
  width: 100%;
  text-align: start;
  line-height: 28px;
  text-align: justify;
}
.principle_contents{
  margin-top: 20px;
}
.principal_message {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.principal_message img {
  object-fit: cover;
  width: 85%;
  margin-top: 20px;
  margin-left: 1.2rem;
  border-radius: 10px;
}
.principal_message p {
  width: 95%;
  text-align: start;
  line-height: 28px;
  text-align: justify;
}
.feature_message p {
  width: 95%;
  text-align: justify;
}
.feature_message{
  margin-top: 20px;
}

.why-us {
  width: 100%;
  margin-top: 20px;
}
.why-us p {
  width: 95%;
  text-align: justify;
}
.brochures-content{
  margin-top: 20px;
}
.gallary-content{
  margin-top: 20px;
}
.sliderr{
  display: none;
}



/* media query */

@media (max-width: 992px) {
  .school_info {
    display: grid;
    margin-top: 5vh;
    margin-left: 5%;
    padding: 2rem 2rem;
    width: 9%;
  }
  .brochure {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    padding: 0 20px 0 0;
  }
  .gallary {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 8px;
    padding: 0 20px 0 0;
  }
  .gallary img {
    width: 10vw;
    height: 20vh;
    object-fit: cover;
  }
  .brochure img {
    width: 10vw;
    height: 20vh;
    object-fit: cover;
  }

}

@media (max-width: 768px) {
  .school-container {
    width: 100%;
  }
  .school_info {
    display: grid;
    margin-left: -30px;
    margin-top: 10px;
    width: 100%;
  }
  .info_contents {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .info_contents img {
    object-fit: cover;
    width: 90%;
    margin-top: 10px;
  }
  .info_contents p {
    width: 100%;
    text-align: start;
    line-height: 28px;
    text-align: justify;
  }
  .principal_message {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .principal_message img {
    object-fit: cover;
    width: 90%;
    margin-top: 10px;
  }
  .school_info label {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
    font-size: 30px;
  }
  .brochure {
    display: grid;
    grid-template-columns: repeat(4, 2fr);
    gap: 3px;
    padding: 0 20px 0 0;
  }
  .gallary {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3px;
    padding: 0 20px 0 0;
  }
  .gallary img {
    width: 22vw;
    height: 22vh;
    object-fit: cover;
  }
  .brochure img {
    width: 22vw;
    height: 22vh;
    object-fit: cover;
  }

  .school_info {
    margin-top: 0px;
  }
  .sliderr-container {
    margin-left: 1%;
    width: 95%;

  }
  .sliderr {
    display: flex;
    justify-content: flex-start;
    width: 95%;

  }
  .slider-title{
    margin-left: 1%;
  }

  .slider-title label{
    display: flex;
      justify-content: flex-start;
      margin-top: 25px;
      font-size: 30px;
  }
  .slider-title h4{
    background-color: #e26029;
    width: 100px;
    border-radius: 15px;
    height: 5px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
.school_container{
  margin-bottom: -30px;
}
.owl-nav{
  display: none;
}

}

@media (max-width: 480px) {
  .school-container {
    width: 100%;
  }
  .school_info {
    display: grid;
    margin-left: 0;
    padding: 2rem 2rem;
    width: 100%;
  }
  .info_contents {
    width: 100%;
    display: grid;
    grid-template-columns: none;
    padding-bottom: 10px;
  }
  .info_contents img {
    object-fit: cover;
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
  }
  .principal_message {
    width: 100%;
    display: grid;
    grid-template-columns: none;
  }
  .principal_message img {
    object-fit: cover;
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
  }
  .school_info label {
    display: flex;
    justify-content: flex-start;
    margin-top: 15px;
    font-size: 30px;
  }
  .brochure {
    display: grid;
    grid-template-columns: repeat(2, 5fr);
    gap: 5px;
    padding: 0 20px 0 0;
  }
  .gallary {
    display: grid;
    grid-template-columns: repeat(2, 5fr);
    gap: 5px;
    padding: 0 20px 0 0;
  }
  .gallary img {
    width: 38vw;
    height: 24vh;
    object-fit: cover;
  }
  .brochure img {
    width: 38vw;
    height: 24vh;
    object-fit: cover;
  }
  .principal_message p {
    width: 100%;
    text-align: start;
    line-height: 28px;
    text-align: justify;
  }
  .feature_message p {
    width: 100%;
    text-align: justify;
  }

  .why-us {
    width: 100%;
  }
  .why-us p {
    width: 100%;
    text-align: justify;
  }

  .school_info {
    margin-top: 0px;
  }
  .sliderr-container{
      margin-left: 3%;
      width: 100%;
    }
    .sliderr{
      display: flex;
      justify-content: flex-start;
    }
    .slider-title {
      margin-left: 6%;
    }
    .slider-title label{
      display: flex;
        justify-content: flex-start;
        margin-top: 25px;
        font-size: 30px;
    }
    .slider-title h4{
      background-color: #e26029;
      width: 100px;
      border-radius: 15px;
      height: 5px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .owl-nav{
      display: none;
    }
}
