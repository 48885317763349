@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

@import "../styles/variables.scss";

html,
body {
  overflow-x: hidden;
}

body {
  font-family: "DM Sans", sans-serif;
}

.d-flex {
  display: flex;
  align-items: center;
}
.text-initial {
  text-align: initial;
}

.blue-text {
  color: $primaryColor;
}

.field-input:focus-visible {
  outline: none;
}
.error {
  color: #e03a3a;
}

.admission-form {
  border-bottom: 1px solid rgba(224, 224, 224, 0.8117647059);
  padding-bottom: 17px;

  .star {
    color: $tagred;
  }
  .option-head {
    .main-content {
      color: $primaryColor;
    }
    .small-title {
      color: $white;
      background-color: $primaryColor;
      padding: 8px;
      margin-bottom: 20px;
    }
  }
  .field-input-block {
    margin-bottom: 15px;
  }

  .admission-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    .school-name {
      margin-bottom: 5px;
      color: $primaryColor;
      font-size: 28px;
      font-weight: 400;
      padding-bottom: 0;
    }
    .address-wrapper {
      .address-info {
        display: flex;

        p {
          margin-bottom: 0;
        }
        .side-bar {
          border-right: 1px solid $gray;
          margin: 3px 10px;
        }
      }
    }
  }
  .tag {
    color: $tagred;
    margin-bottom: 20px;
  }
  .name-tag {
    margin-bottom: 8px;
  }
  .next-btn {
    margin: auto;
    width: fit-content;
    .main-btn {
      color: $white;
      background-color: $primaryColor;
      padding: 7px 20px;
      .next {
        i {
          font-size: 20px;
          color: $white;
        }
      }
    }
  }
}
.field-input {
  border: 1px solid rgba(224, 224, 224, 0.8117647059);
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
  color: rgba(109, 109, 109, 0.9803921569);
  background: #fff;
  resize: none;
}
.field-input-block {
  display: flex;
  flex-direction: column;
}

.text-end {
  text-align: end;
}
.section-top {
  margin-top: 80px;
}

.section-gap {
  padding: 20px 0;
}

.main-content {
  font-size: 14px;
  font-weight: 400;
  color: #222;
}

.custom-card {
  width: fit-content;
  padding: 8px;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  margin: 5px 15px;

  img {
    height: 95px;
    width: 95px;
  }
}
.d-column {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.content-style {
  background-color: $secondaryColor;
  width: 100px;
  border-radius: 15px;
  height: 5px;
  margin: auto;
  margin-bottom: 20px;
}

.main-btn {
  padding: 12px 25px;
  border-radius: 50px;
  background-color: $secondaryColor;
  color: $white;
  border: none;
  font-size: 18px;
}

.main-title {
  font-size: 40px;
  padding-bottom: 15px;
}

.title-content {
  font-size: 20px;
  font-weight: 500;
  color: $black;
  margin: 20px 0 10px;
}

.content-text {
  color: $secondaryColor;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}
.label-text {
  color: $labelColor;
}

.content {
  font-size: 16px;
  line-height: 28px;
}

.gray-text {
  font-size: 15px;
  color: $gray;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link-content {
  font-size: 16px;
  color: $primaryColor;
  font-weight: 500;
}

.mgt-30 {
  margin-top: 30px !important;
}

.border-left {
  border-left: 1px solid $borderColor;
}

.text-init {
  text-align: initial;
}

.alignCenter {
  display: flex;
  align-items: center;
}

.card-section {
  background-color: $white;
  box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
  padding: 20px;
  margin-bottom: 20px;
  min-height: 200px;
  margin: 30px 30px 20px;
  border-radius: 10px;
}

.sub-content-text {
  font-size: 20px;
}

.fullwidth {
  width: 100%;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.text-center {
  text-align: center;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-half {
  .banner-section {
    .banner-content {
      width: 50%;
      top: 120px;

      .main-title {
        font-size: 30px;
        padding-bottom: 0;
      }

      p {
        margin: 0;
      }
    }

    .banner-bg {
      height: 330px;
    }

    .app-img,
    .main-btn {
      display: none;
    }
  }
}

.position-r {
  position: relative;
}

.position-a-lg {
  position: absolute;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: "375px") {
  .main-title {
    font-size: 22px;
  }
}

@media (max-width: "480px") {
  .main-title {
    font-size: 27px;
  }

  .banner-half {
    .banner-section {
      .banner-content {
        top: 75px;
        width: 90%;

        .main-title {
          font-size: 20px;
        }

        p {
          font-size: 14px;
        }
      }
    }
  }

  .position-a-lg {
    position: unset;
  }
}
