@import "../../styles/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

.testimonal-section {
  position: relative;
  .position-title {
    position: absolute;
    top: 25px;
    left: 95px;
  }
  .img-logo {
    display: none;
    margin-right: 10px;
  }
  .bottom-text {
    display: flex;
    margin-top: 20px;
    align-items: center;
    .no-mg-bottom {
      border-bottom: 0px !important;
      padding: 0px 11px;
      border-radius: 25px;
      margin-left: 20px;
      background-color: $primaryColor;
      color: $white !important;
    }
  }
  .bottom-part {
    display: flex;
    align-items: flex-start;
  }
  .logo-img {
    width: 45px !important;
    height: 45px !important;
    border-radius: 50%;
  }
  .card-width {
    display: flex;
    justify-content: center;
  }
  .content-style {
    position: absolute;
    top: 82px;
    left: 95px;
  }
  .width-60 {
    width: 60%;
    position: relative;
  }
  .width-30 {
    width: 40%;
  }
  .quote-img {
    position: absolute;
    top: 10px;
    right: 25px;
  }
  .owl-prev {
    position: unset !important;
    margin: 0 20px !important;
    color: $gray !important;
  }
  .owl-next {
    position: unset !important;
    color: $gray !important;
    margin: 0 20px !important;
  }
  .title-content {
    margin: 0;
  }
  .owl-stage-outer {
    position: relative;
  }
  .owl-nav {
    position: absolute;
    bottom: -15px;
    width: 100%;
  }
  .swipe-section {
    .app-img {
      width: 70px;
      height: 50px;
      margin-right: 20px;
    }
    .swipe-content {
      .card-section {
        position: absolute !important;
        bottom: 50px;
        padding: 24px;
        display: flex;
        position: relative;
        min-height: 235px;
        text-align: left;
        width: 85%;
        .mg-botton-20 {
          margin-bottom: 20px;
        }
        .bold-font {
          font-family: DM Sans;
          font-size: 40px;
          font-weight: 700;
        }

        .content {
          display: -webkit-inline-box;
          color: $gray;
          font-weight: 400;
          line-height: 28px;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .tri-clip {
          clip-path: polygon(100% 0, 0 0, 49% 63%);
          padding: 10px;
          background: #ffffff;
          width: 25px;
          position: absolute;
          bottom: -20px;
          left: 50%;
        }
      }

      .card-info {
        padding: 20px;
        justify-content: center;

        .info-details {
          margin-left: 10px;
          text-align: initial;
          margin-top: 20px;

          .main-title {
            font-size: 24px;
            padding-bottom: 0;
            margin-bottom: 0;
            font-weight: 700;
          }

          .content {
            font-size: 16px;
          }
        }

        .info-img {
          height: 480px;
          width: 100%;
          border-radius: 10px;
          object-fit: cover;
        }
      }
    }

    .owl-next {
      position: absolute;
      top: 80px;
      right: -30px;
      font-size: 50px !important;
    }

    .owl-prev {
      position: absolute;
      top: 80px;
      left: -35px;
      font-size: 50px !important;
    }

    .owl-prev:hover {
      background-color: white !important;
      color: $primaryColor !important;
    }

    .owl-next:hover {
      background-color: white !important;
      color: $primaryColor !important;
    }

    .owl-dots {
      display: none;
    }
  }
}

@media (max-width: 992px) {
  .testimonal-section {
    .card-section {
      margin: 0;
    }
    .app-img {
      width: 45px !important;
      height: 35px !important;
      margin-right: 12px !important;
    }
    .card-section {
      padding: 12px !important;
    }
    .content-style {
      left: 100px;
    }
    .position-title {
      top: 28px;
      left: 85px;
    }
    .owl-nav {
      bottom: 7px;
    }
    .card-section {
      bottom: 95px !important;
    }
    .swipe-section {
      .owl-prev {
        margin: 0 10px !important;
      }

      .owl-next {
        margin: 0 10px !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .testimonal-section {
    .position-title {
      position: unset !important;
    }
    .width-60 {
      width: 65% !important;
    }

    .info-img {
      height: 300px !important;
      width: 233px !important;
    }
    .content-style {
      position: unset !important;
    }
    .swipe-section {
      .owl-nav {
        bottom: -50px;
        .owl-next {
          top: 120px;
        }
        .owl-prev {
          top: 120px;
        }
      }

      .swipe-content {
        .card-section {
          min-height: 295px !important;
          bottom: 25px !important;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .testimonal-section {
    .width-30 {
      display: none;
    }

    .swipe-section {
      .swipe-content {
        .width-60 {
          width: 90% !important;
        }
        .card-section {
          position: unset !important;
          width: 100%;
          .content {
            display: -webkit-inline-box;
            -webkit-line-clamp: 8;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .img-logo {
      display: block;
    }
    .owl-nav {
      top: 100px;
      bottom: unset;
    }
    .owl-prev {
      left: -5px !important;
      float: left;
    }

    .owl-next {
      right: -5px !important;
      float: right;
    }
  }
}
@media (max-width: 376px) {
  .testimonal-section {
    .bottom-text {
      display: unset;
      margin-top: 0px;
    }
    .app-img {
      display: none !important;
    }
  
    .no-mg-bottom {
      margin: 10px 0 !important;
    }
  }
  }
