@import "../../styles/variables.scss";

.personal-info {
  .admission-form {
    .tag {
      color: $tagred;
      margin-bottom: 20px;
      text-align: initial;
    }
    .option-head {
      text-align: initial;
      width: 100%;
      .main-content {
        color: $primaryColor;
        margin-bottom: 0;
      }

      // .field-input {
      //   margin-bottom: 10px;
      // }

      .field-input:focus-visible {
        border: 1px solid rgba(224, 224, 224, 0.811765);
        outline: none;
      }
      .small-title {
        color: $white;
        background-color: $primaryColor;
        padding: 8px;
        margin-bottom: 20px;
      }
    }
    .name-tag {
      margin-bottom: 8px;
    }
    .gender-row {
      justify-content: space-between;
      align-items: flex-start;
    }
    .next-btn {
      margin: auto;
      width: fit-content;
      .main-btn {
        color: $white;
        background-color: $primaryColor;
        padding: 7px 20px;
        .next {
          i {
            font-size: 20px;
            color: $white;
          }
        }
      }
    }
    .field-input-block {
      margin-bottom: 15px;
      .content {
        margin-right: 5px;
        display: block ruby;
      }
      .field-input {
        padding: 7px 10px;
      }
      .age-block {
        .field-input {
          width: 200px;
          margin-left: 15px;
        }
      }
    }
    .start-field {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: row;
      .main-content {
        margin-top: 8px;
      }
      .date-section {
        span {
          margin: 0 16px;
        }
        .date {
          margin-bottom: 10px;
        }
        input {
          width: 200px;
        }
      }
    }
    .image-block {
      height: 120px;
      width: 120px;
      border-radius: 5px;
      border: 1px solid $gray;
      margin-left: auto;
      text-align: center;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .student-image {
        height: 100%;
        width: 100%;
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }

      .no-input {
        display: none;
      }
      .block-content {
        cursor: pointer;
      }
    }

    .image-icon {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      border: 2px solid $gray;
      background: $gray;
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 13px;
        width: 13px;
        object-fit: contain;
      }
    }
    .radio-tab {
      .radio-block {
        margin: 0 0 8px 10px;
        .radio-label {
          label {
            margin: 0 10px 0 4px;
          }
        }
      }
    }
    .checkbox-block {
      margin-bottom: 15px;
    }
    .radio-tab {
      input[type="radio"] {
        -webkit-appearance: none;
        width: 18px;
        height: 18px;
        border: 2px solid darkgray;
        border-radius: 50%;
        outline: none;
        &:hover,
        &:active,
        &:checked {
          //   border: 2px solid $tagred;
        }
      }

      input[type="radio"]:before {
        content: "";
        display: block;
        width: 70%;
        height: 70%;
        margin: 2px;
        border-radius: 50%;
      }
      input[type="radio"]:checked:before {
        background: $tagred;
        border: 2px solid $tagred;
      }
    }
  }
}
