@import "../../styles/variables.scss";

.admission-form-section {
  border: 1px solid rgba(224, 224, 224, 0.8117647059);
  padding: 15px;
  border-radius: 5px;

  .info-head {
    margin: 8px 0;
  }
  .admission-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      margin-bottom: 5px;
    }
  }
  .input-field-block {
    margin-bottom: 15px;
    .content {
      margin-right: 5px;
      display: block ruby;
    }
    .input-field {
      padding: 7px 10px;
    }
  }
  .image-block {
    height: 120px;
    width: 120px;
    border-radius: 5px;
    border: 1px solid $borderColor;
    margin-left: auto;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .student-image {
      height: 100%;
      width: 100%;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }

    .no-input {
      display: none;
    }
    .block-content {
      cursor: pointer;
    }
  }

  .image-icon {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 2px solid $borderColor;
    background: $borderColor;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 13px;
      width: 13px;
      object-fit: contain;
    }
  }
}
.checkbox-block {
  margin-bottom: 15px;
}
.navbar {
  text-align: center;
  height: 55px;
}

// .navbar img {
//   display: block;
//   margin: 0 auto;

// }
.navbar button {
  margin-left: 55px;
  border: none;
  background: white;
}
.admissionform {
  .aimg {
    display: block;
    margin: 0 auto;
  }
}
.link {
  cursor: pointer;
}
