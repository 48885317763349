.container {
  max-width: 1340px;
}
.pop_headers {
  display: flex;
  gap: 10px;
}
.owl-img {
  margin-bottom: 10px;
}
.school_contents {
  display: grid;
  place-items: start;
}
.school_contents h1 {
  font-size: 3vh;
  margin-top: 1vh;
}
.school_contents h2 {
  font-size: 2vh;
  color: #808080;
}

.pop_headers img {
  width: 80px;
}
.pop_div {
  justify-content: flex-start;
  display: flex;
  margin-top: 35px;
  gap: 6vw;
}

.addmission_button {
  margin-top: 10px;
  padding: 12px 25px;
  background-color: #29abe2;
  color: #fff;
  border: none;
  border-radius: 50px;
  font-size: 18px;
}
.addmission_button:hover {
  background-color: #e26029;
  cursor: pointer;
}
/* */
.pop-body {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
/* ------------ releted school container ------------------ */
.pop-school {
  margin-top: 8vh;
}
.releted-school label {
  display: flex;
  margin-left: 10px;
  justify-content: flex-start;
  font-size: 35px;
}
.releted-school {
  display: grid;
  gap: 15px;
}
.pop-school h5 {
  background-color: #e26029;
  width: 30%;
  height: 5px;
  border-radius: 15px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
}

@media (max-width: 992px) {
  .pop-body {
    display: block;
  }
  .pop_headers {
    gap: 5px;
  }
  .pop_div {
    margin-top: 10px;
    margin-left: 5%;
    display: flex;
    gap: 20vw;
  }
  .pop_headers img {
    width: 80px;
    height: 80px;
  }
  .school_contents h1 {
    font-size: 18px;
    font-weight: 600;
    text-align: start;
    margin-top: 1vh;
  }
  .school_contents h2 {
    font-size: 15px;
  }
  .pop-school {
    margin-top: 15vh;
    margin-left: 10px;
    width: 90%;
  }
  .pop-body {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 768px;
  }
  .pop-body {
    display: block;
  }
  .pop-container .owl-card-wrapper {
    height: 158px;
    margin-bottom: 10px;
  }
  .owl-stage {
    height: 225px;
  }
  .pop_headers {
    gap: 5px;
  }
  .pop_div {
    margin-top: 10px;
    display: flex;
    gap: 11vw;
    margin-left: -15px;
  }
  .pop_headers img {
    width: 80px;
    height: 80px;
  }
  .school_contents h1 {
    font-size: 17px;
    font-weight: 600;
    text-align: start;
    margin-top: 1vh;
  }
  .school_contents h2 {
    font-size: 15px;
  }
  .pop-school {
    display: none;
  }
}

@media (max-width: 480px) {
  .pop-body {
    display: block;
  }
  .pop_div {
    display: grid;
    margin-left: 3%;
    align-items: center;
    margin-top: 1px;
    gap: 15px;
  }
  .pop_headers img {
    width: 100px;
    height: 100px;
  }
  .school_contents {
    display: block;
    margin-top: 15px;
  }
  .school_contents h1 {
    font-size: 17px;
    font-weight: 600;
    text-align: start;
    margin-top: 10px;
  }
  .school_contents h2 {
    font-size: 15px;
  }
  .pop-school {
    display: none;
  }
}
