$primaryColor: #29abe2;
$lightprimaryColor: #eaf7fc;
$secondaryColor: #e26029;
$lightSecondaryColor: #fcefea;
$lightGreen: #ecf4ef;
$green: #40975f;
$purple: #722ab5;
$lightPurple: #f1e9f7;
$borderColor: #d6dde6a6;
$black: #303030;
$white: #fff;
$accentColor: #101c3d;
$gray: gray;
$outlinewhite: #ffffff99;
$primaryBorder: #bfe6f64f;
$historybgblue: #eaf7fc;
$historybgpink: #fef2ed;
$labelColor: #a6a6a6;
$blockShadow: 0px 4px 8px #cccccc59;
$tagred: #dd4b39;
