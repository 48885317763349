@import "../../styles/variables.scss";
.demo-section {
  .demo-content {
    height: 150px;
    background-color: $primaryColor;
    padding: 30px;
    border-radius: 10px;
    text-align: center;

    h2 {
      font-size: 32px;
      color: $white;
      text-align: initial;
    }
    img {
      height: 80px;
      width: 100%;
      object-fit: contain;
    }

    .main-btn {
      background-color: $white;
      color: $secondaryColor;
      font-weight: 500;
    }

    .request-demo {
      img {
        height: 20px;
        margin-bottom: 10px;
      }

      .shine {
        transform: rotate(180deg);
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 992px) {
  .demo-section {
    .demo-content {
      h2 {
        font-size: 26px;
      }
    }
  }
}

@media (max-width: 768px) {
  .demo-section {
    .demo-content {
      .main-btn {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 480px) {
  .demo-section {
    .demo-content {
      height: 185px;
      img {
        display: none;
      }
      h2 {
        font-size: 24px;
      }

      .request-demo {
        text-align: initial;
      }
    }
  }
}

@media (max-width: 360px) {
  .demo-section {
    .demo-content {
      height: 175px;
      h2 {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 360px) {
  .demo-section {
    .demo-content {
      height: 195px;
    }
  }
}
