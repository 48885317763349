.product-section {
  .mgt-30 {
    margin-top: 100px !important;
  }
  .product-contain {
    .content {
      font-size: 22px;
      font-weight: 500;
    }

    .d-flex {
      margin-left: 10px;
      margin-bottom: 20px;
      i {
        color: #29abe2;
        width: 50px;
        font-size: 22px;
        display: flex;
        align-items: center;
      }

      p {
        width: calc(100% - 50px);
        margin-bottom: 5px;
      }
    }
  }
}

@media (max-width: 480px) {
  .product-section {
    .product-contain {
      margin: 20px 0;
    }
  }
}
