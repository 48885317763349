@import "../../styles/variables.scss";

.webservice-section {
  .web-bg {
    position: relative;
    img {
      width: 100%;
    }

    .webservice-content {
     
      width: 100%;
      text-align: center;

      .main-title {
        font-size: 40px;
      }
    }

    .web-img {
      background-image: url("../../images/webservice-img/pattern.svg");
      img {
        width: 70%;
      }
    }
  }
}

@media (max-width: 1380px) {
  .webservice-section {
    .web-bg {
      .web-img {
        img {
          height: 495px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .webservice-section {
    .web-bg {
      .web-img {
        img {
          height: 335px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .webservice-section {
    .web-bg {
      .web-img {
        img {
          height: 230px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .webservice-section {
    height: 500px;
    
    .web-bg {
      height: 100%;
      .webservice-content {
        padding: 10px 30px;
        .main-title {
          font-size: 27px;
        }
      }

      img {
        // height: 100%;
        position: unset;
      }
    }
  }
}
