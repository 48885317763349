@import "../../styles/variables.scss";

.newaccordian-section {
  .accordion-item:first-of-type .accordion-button {
    color: #00000094;

  }

  .accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
    transform: scale(0.7) !important;
    margin-left: 0;
    margin-right: 15px;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
  }

  .accordion-item {
    border-bottom: 2px solid rgb(214, 206, 206);
    margin-bottom: 20px;
    border: none;
  }

  .accordion-button:not(.collapsed) {
    border-color: #fff;
    background-color: #fff;
    color: #00000094;
  }

  .accordion-button:focus {
    border-color: #fff;
    box-shadow: none;
    color: #00000094;
  }

  .accordion-button {
    padding: 20px 0;
    border: none;
    border-bottom: 1px solid $borderColor;
    font-size: 20px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    cursor: pointer;
  }

  .card-body {
    padding: 0;
    text-align: initial;

    .mgl-3 {
      margin-left: 35px;
    }
  }

  .accordion-button::after {
    // background-color: aliceblue;
    // padding: 20px;
  }

  // .accordion-button:not(.collapsed) {
  //   color: #0c63e4;
  //   background-color: #feb7ee;
  // }
}