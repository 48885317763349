.stepper-bottom {
  .main-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    .loader {
      border: 2px solid #f3f3f3;
      border-top: 2px solid #3498db;
      border-radius: 50%;
      width: 23px;
      height: 23px;
      animation: spin 2s linear infinite;
      margin: 4px 12px;

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
}
