@import "../../styles/variables.scss";

.other-info {
  text-align: initial;
  .otherinfo-detail {
    .other-input {
      display: flex;

      .input-label {
        margin-right: 10px;

        input {
          margin-right: 8px;
        }
      }
    }
  }
}
