.parents-info {
  .admission-form {
    .fathers-detail {
      text-align: initial;
      .blue-text {
        margin-bottom: 10px;
      }
    }
  }
}
